@import '../../../../theme/variables.scss';

.submitButton {
  margin-top: 25px;
}

.box {
  padding: 15px;
}

.boxBottom {
  background-color: $gray1;
  margin: 0 15px;

  h4 {
    color: $primary-color;
    font-weight: 800;
    margin-bottom: 5px;
    text-transform: uppercase;
  }
}
