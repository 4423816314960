@import '../../../../theme/variables.scss';

.result {
  background-color: $gray9;
  margin: 15px 15px 0;
  overflow: hidden;
  padding: 10px 0 0;
}

.text {
  font-size: 11px;
  font-weight: 600;
  line-height: 14px;
  margin-bottom: 0;
  text-transform: uppercase;
}

.number {
  color: $blue3;
  font-size: 35px;
  font-weight: 600;
}
