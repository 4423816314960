@import '../../../theme/variables.scss';

.switcher {
  cursor: pointer;
  display: inline-block;
  margin-right: 10px;
  overflow: visible;
  padding-top: 15px;
  position: relative;
}

.current {
  margin-top: 5px;

  i {
    color: $blue-light;
    vertical-align: middle;
  }
}

.select {
  background-color: $white;
  border: 1px solid $gray27;
  display: block;
  padding: 0;
  position: absolute;
  right: -21px;
  text-align: center;
  top: 76px;
  width: 150px;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      padding: 10px;
    }
  }

  &::after,
  &::before {
    border-style: solid;
    content: '';
    display: block;
    height: 0;
    left: 102px;
    position: absolute;
    width: 0;
  }

  &::after {
    border-color: transparent transparent $white;
    border-width: 14px;
    left: 103px;
    top: -28px;
  }

  &::before {
    border-color: transparent transparent $gray4;
    border-width: 15px;
    top: -30px;
  }
}
