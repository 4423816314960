@import '../../../theme/variables.scss';

// scale
@mixin scale($scale) {
  transform: scale($scale);
}

.toast {
  background-color: $toast-background-color;
  color: $toast-color;
  font-size: 1.3em;
  padding: 20px;
  position: fixed;
  right: 60px;
  top: 115px;
  width: 300px;
  z-index: 1000;

  .closeToastBtn {
    background: none;
    border: 0;
    cursor: pointer;
    margin: 0;
    outline: none;
    padding: 0;

    &:hover,
    &:focus {
      @include scale(1.2);
      color: $white;
    }
  }
}
