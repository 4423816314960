@import '../../../theme/variables.scss';

.editableCheckbox {
  .inlineLabel {
    color: $main-text-color;
  }
}

.inline {
  display: inline-block;
  margin-bottom: 0;
  margin-right: 20px;
}
