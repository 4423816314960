@import '../../../theme/variables.scss';

@mixin very-short {
  @media only screen and (max-height: 620px) {
    @content;
  }
}

@mixin laptop {
  @media only screen and (max-height: 960px) {
    @content;
  }
}

@mixin short {
  @media only screen and (max-height: 825px) {
    @content;
  }
}

.sidebar {
  bottom: 0;
  position: fixed;
  top: $nav-bar-height;
  width: $side-bar-width;
  z-index: 999;
}

.login {
  top: 0;
}

.navItemList {
  list-style-type: none;
  margin-bottom: 0;
  padding-left: 0;

  > li {
    border-bottom: 1px solid $side-bar-item-border-color;
  }
}

.itemIconSection {
  background-color: $side-bar-background-color;
  color: $secondary-color;
  display: inline-block;
  height: 100%;
  padding: 18px 8px;
  text-align: center;
  vertical-align: top;
  width: $side-bar-width;

  @include very-short {
    padding: 9px 8px;

    div {
      display: inline-block;
    }
  }

  @include short {
    padding: 9px 8px;
  }

  i {
    font-size: 45px;
    margin-bottom: 10px;

    @include laptop {
      font-size: 30px;
    }

    @include short {
      font-size: 22px;
    }

    @include very-short {
      font-size: 18px;
      margin-right: 5px;
      vertical-align: middle;
    }
  }
}

.itemExpandedSection {
  background-color: $side-bar-expanded-background-color;
  color: $side-bar-text-color;
  display: block;
  height: 100%;
  padding: 32px 10px;
  vertical-align: top;
  width: $side-bar-expanded-width - $side-bar-width;
}

.navItemIcon {
  font-size: 35px;

  &.bl-half {
    /// special for profiles
    font-size: 24px;
    line-height: 50px;

    +hr {
      margin-top: 6px;
    }
  }
}

.navItemList > .active,
.navItemList > li:hover {
  .itemIconSection,
  .itemExpandedSection {
    background-color: $body-color;
    color: $header-color;

    hr {
      display: none;
    }
  }
}

.remaining {
  height: 100%;
  overflow: hidden;
  white-space: nowrap;
}

.remainingIcons {
  background-color: $side-bar-background-color;
  display: inline-block;
  height: 100%;
  width: $side-bar-width;
}

.remainingExpanded {
  background-color: $side-bar-expanded-background-color;
  display: inline-block;
  height: 100%;
  width: $side-bar-expanded-width - $side-bar-width;
}
