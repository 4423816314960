@import '../../../theme/variables.scss';

$nav-bar-shadow-height: 2px;

.navbar {
  background: $white;
  box-shadow: 0 $nav-bar-shadow-height 10px 0 $outline-color;
  height: $nav-bar-height - $nav-bar-shadow-height;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;

  a {
    color: $blue-link;
  }
}

.waffleButton {
  &:hover :global(.biolabicons) {
    color: $waffle-hover-colour;
  }
}

.apps {
  display: inline-block;
  margin-left: 10px;
  padding-top: 20px;

  :global(.biolabicons) {
    color: $blue-light;
    font-size: 30px;
  }
}

.navWrapper {
  margin-left: $side-bar-width;
}

.logo {
  margin-left: -$side-bar-width;
}

.navItem {
  cursor: pointer;
  height: 45px;
  margin-top: 15px;

  a {
    font-size: 14px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;

    > .icon {
      font-size: 25px;
    }

    &:hover,
    &:focus {
      background: transparent;
    }
  }
}
