@import '../../../../../theme/variables.scss';

.storeTitle {
  color: $primary-color;
  font-weight: 800;
  margin-bottom: 10px;
}

.user a {
  color: $blue-light;
}
