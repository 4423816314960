@import '../../../../theme/variables.scss';

.exportButton {
  button {
    // scss-lint:disable ImportantRule
    border-color: $call-to-action-color;
    color: $call-to-action-color;
    font-size: 15px !important;
    font-weight: 600;
    padding: 5px 25px;
  }
}

.usersReport {
  :global(.col-sm-6):nth-child(2n + 1) {
    clear: both;
  }
}
