@import '../../../theme/variables.scss';

.card {
  background-color: $white;
  border: 1px solid $outline-color;
  min-height: 200px;
  position: relative;
  width: 100%;

  // Space out consecutive cards
  + .card {
    margin-top: 20px;
  }

  + :global(.card) {
    margin-top: 20px;
  }

  h3 {
    font-size: 18pt;
    font-weight: 600;
    text-align: center;

    i { margin-right: 8px; }
  }

  h2 {
    margin-left: 0;
    margin-top: 0;
  }

  h4 {
    margin-top: 15px;
  }

  hr {
    border-color: $gray2;
    margin: 10px 0;
  }

  .header {
    background-color: $header-color;
    color: $white;
    height: 47px;
    padding: 10px 10px 10px 20px;
    position: relative;

    &.lightHeader {
      background-color: $blue-light;
    }

    &.noPadding {
      padding: 0;
    }

    h3 {
      text-align: left;
    }
  }

  .body {
    font-size: 13px;
    padding: 20px;

    &.noPadding {
      padding: 0;
    }

    .separator {
      color: $header-color;
      font-weight: bold;
    }
  }

  .footer {
    padding: 5px 20px;

    button {
      width: 100%;

      &:global(.btn-dashed.full-body-button) {
        border-radius: 0;
        margin-bottom: -6px;
        margin-left: -21px;
        width: calc(100% + 42px);
     }
    }
  }
}
