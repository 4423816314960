@import '../../../../theme/variables.scss';

.result {
  background-color: $gray9;
  border: 1px solid $gray2;
  margin: 0 15px;
  overflow: hidden;
  padding: 25px 0 0;
}

.text {
  font-size: 11px;
  font-weight: 600;
  line-height: 14px;
  margin-bottom: 0;
  text-transform: uppercase;
}

.number {
  color: $blue3;
  font-size: 35px;
  font-weight: 600;
  line-height: 35px;
  margin-bottom: 20px;
}

.bottom {
  border-top: 1px solid $gray2;
  padding: 10px 0 0;
  text-transform: uppercase;
}

.light {
  > div {
    background-color: $white;
  }
}

.textGray {
  div p {
    // scss-lint:disable ImportantRule
    color: $gray27 !important;
  }
}
