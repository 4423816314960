@import '../../../theme/variables.scss';

.striped {
  width: 100%;

  &:nth-child(2n) {
    background-color: $white;
  }

  &:nth-child(2n + 1) {
    background-color: $box-color;
  }
}
