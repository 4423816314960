@import '../../../theme/variables.scss';

.paginatedTable {
  thead > tr > th {
    border-bottom-width: 0;
  }

  thead tr {
    background-color: $header-color;
    color: $white;
  }

  tbody span {
    border-radius: 0;
    margin-right: 5px;
    padding: 4px 8px;
  }

  ul {
    margin-top: 0;

    li a {
      background-color: transparent;
      border: 0;
      color: $blue-light;
      cursor: pointer;
      font-size: 16px;
      font-weight: 800;
      text-decoration: underline;

      &:hover {
        color: $black;
      }
    }

    .activePage {
      color: $black;
      cursor: default;
    }

    .misclinks {
      text-decoration: none;
    }
  }
}
