@import '../../../../../theme/variables.scss';

:local {
  .submitButton {
    margin-top: 25px;
  }
  .warningText {
    margin-top: 30px;
    text-align: end;
  }

  .inactiveUsersRow {
    padding-top: 10px;
    padding-bottom: 25px;
  }

  .orphanedRow {
    padding-top: 20px;
    padding-bottom: 10px;
    border-top: 1px solid #d2d3d3;
  }
}
