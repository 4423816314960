@import '../../../../theme/variables.scss';
@import '../../../../theme/mixins';

.title {
  // scss-lint:disable ImportantRule
  display: inline-block;
  margin-top: 6px !important;
}

.buttons {
  @include right-icon-section(1.3em, $blue-light);

  :global(.btn) {
    padding: 0;
  }
}
