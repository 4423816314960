@import '../../../../../theme/variables.scss';

.provisionApp {
  border-top: 1px solid $outline-color;
  margin-bottom: 20px;
  padding: 12px 25px 0;

  &:first-child {
    border-top: 0;
  }

  .heading {
    margin-bottom: 15px;
    text-align: left;
  }
}

.header {
  background-color: $header-color;
  color: $white;
  margin-top: 15px;
}
