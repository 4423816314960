@import '../../../theme/variables.scss';

.footer {
  background-color: $gray3;
  font-size: 12px;
  height: $footer-height;
  margin-top: $footer-spacing;
  padding-top: 10px;
  position: relative;
  width: 100%;
  z-index: 1000;
}

.info {
  p {
    color: $white;
  }
}

.spacer {
  padding-left: 140px;
}


.legalLink {
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}
