@import '../../../../theme/variables.scss';

.app {
  background-color: $gray1;
  border: 1px solid $outline-color;
  border-left: 0;
  cursor: pointer;
  font-weight: 600;
  padding: 10px 10px 10px 20px;

  &.active {
    background-color: $white;
    border-right: 0;
  }

  &:first-child {
    border-top: 0;
  }

  .rightCaret {
    color: $header-color;
    float: right;
    font-weight: bold;
  }
}

.addApp {
  color: $header-color;
}
