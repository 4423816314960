@import '../../../../theme/variables.scss';

.table {
  tr {
    // scss-lint:disable ImportantRule
    background-color: transparent !important;
  }
}

.opened {
  display: block;
}

.closed {
  display: none;
}

.selectTitle {
  color: $blue4;
  margin-left: 10px;
}

.permission {
  margin-left: 10px;
}
