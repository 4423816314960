@import '../../../theme/variables.scss';

.profile {
  color: $black;
  display: inline-block;
  padding-top: 15px;
  position: relative;
}

.ctaholder {
  cursor: pointer;
  position: relative;

  :global(.material-icons) {
    color: $blue-light;
    margin-left: -5px;
    vertical-align: middle;
  }
}

.circle {
  color: $blue-light;
  display: inline-block;
  font-size: 30px;
  margin-right: 10px;
  margin-top: -10px; // Offset by same amount as top so padding is uniform
  position: relative;
  text-align: center;
  top: 10px;
  width: 30px;
}

.name {
  display: inline;
  margin-right: 5px;
  margin-top: 5px;
  -webkit-touch-callout: none; // iOS Safari
  user-select: none;
}

.dropdown {
  background-color: $white;
  border: 1px solid $gray27;
  display: block;
  left: -30px;
  padding: 0 5px;
  position: absolute;
  text-align: center;
  top: 80px;
  width: 200px;

  &::after,
  &::before {
    border-style: solid;
    content: '';
    display: block;
    height: 0;
    left: 152px;
    position: absolute;
    width: 0;
  }

  &::after {
    border-color: transparent transparent $white;
    border-width: 14px;
    left: 153px;
    top: -28px;
  }

  &::before {
    border-color: transparent transparent $gray4;
    border-width: 15px;
    top: -30px;
  }

  .link {
    color: $blue-light;
    text-decoration: underline;
  }

  ul {
    margin: 0;
    padding: 0;

    li {
      list-style: none;
      margin: 20px 0;
    }
  }
}
