@import '../../../theme/variables.scss';

.datePicker {
  div > div {
    width: 100%;
  }
}

.pickerHolder {
  position: relative;

  i {
    color: $blue-light;
    font-size: 20px;
    font-weight: 600;
    position: absolute;
    right: 8px;
    top: 4px;
  }
}
