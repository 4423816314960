@import '../../../theme/variables.scss';

// Values for sticky footer
.wrapper {
  height: 100%;
  margin-top: -$footer-height - $footer-spacing;
  padding-top: $footer-height + $footer-spacing;
}

.mainContent {
  margin-bottom: -$footer-height - $footer-spacing; // for footer
  margin-left: $side-bar-width;
  min-height: 100%;
  padding-top: $nav-bar-height + 20px;
  position: relative;
}

.maintenanceNotice {
  width: 100%;
  padding: 24px 12px;
  font-size: 1.3rem;
  font-weight: 600;
  text-align: center;
  color: red;
  margin-top: 88px;
  margin-bottom: -88px;
}

.toastContainer {
  float: right;
  margin-right: 30px;
  margin-top: 100px;
  width: 300px;
}

// spacing for sticky footer
.pushFooter {
  height: $footer-height + $footer-spacing;
}
