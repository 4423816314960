@import '../../../../theme/variables.scss';

$dragging-colour: #555;

:local {
  .outerCard {
    cursor: pointer;
    padding: 7px;

    input {
      margin-right: 20px;
    }
  }

  .draggingCard {
    background-color: $dragging-colour;
    color: $dragging-colour;

    input {
      visibility: hidden;
    }
  }
}
