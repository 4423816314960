@import '../../../../../theme/variables.scss';

.provisionApp {
  border-top: 1px solid $outline-color;
  margin-bottom: 20px;
  padding: 12px 25px 0;

  &:first-child {
    border-top: 0;
  }

  .heading {
    margin-bottom: 15px;
    text-align: left;
  }
}

// Referenced in code so adding here as placeholder; not sure if actually need a style for this though
.active {
  // Empty on purpose
}

.branchProvision {
  border: 1px solid $outline-color;
  margin-bottom: 10px;
  margin-left: 20px;
  margin-right: 20px;
  padding: 10px;
}

.header {
  background-color: $header-color;
  color: $white;
  font-weight: 600;
  padding: 10px 0;
}

.provisionRow {
  padding: 10px 0;

  &.active {
    &:nth-child(n) {
      background-color: $box-color;

      .branchProvision {
        background-color: $white;
      }
    }
  }

  &:nth-child(2n) {
    background-color: $box-color;

    .branchProvision {
      background-color: $white;
    }
  }

  &:nth-child(2n-1) {
    background-color: $white;

    .branchProvision {
      background-color: $box-color;
    }
  }
}
