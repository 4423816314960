@import '../../../theme/variables.scss';

.dealerRow {
  cursor: pointer;

  :global(.icon-button) {
    + :global(.icon-button) {
      margin-left: 10px;
    }

    :global(.biolabicons) {
      color: $blue-light;
    }
  }

  button:first-child {
    margin-left: none;
  }

  &:hover {
    background-color: $gray8;
  }
}
