@import '../../../theme/variables.scss';

$password-input-default: $gray2;
$password-input-weak: #52bce6;
$password-input-medium: #026dbb;
$password-input-strong: #043673;

.bar {
  background-color: $password-input-default;
  float: left;
  height: 2px;
  margin: -7px 1.8% 10px;
  width: calc(100% / 6 - 3%);

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
}

.weak {
  background-color: $password-input-weak;
}

.medium {
  background-color: $password-input-medium;
}

.strong {
  background-color: $password-input-strong;
}

.input {
  padding-left: 14px;
  padding-right: 14px;
}

.scoreContainer {
  padding-left: 14px;
  padding-right: 14px;
  position: relative;
  width: 100%;
}
