@import '../../../../theme/variables.scss';

.unlockUser {
  background-color: $gray2;
  padding: 15px 20px 10px;
}

.lockIcon {
  color: $white;
  font-size: 40px;
}

.unlockButton {
  margin-top: 10px;
}

.lockedMessage {
  color: $blue3;
}
