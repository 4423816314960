@import '../../../theme/variables.scss';

.input {
  color: $secondary-color;
  display: block;
  float: left;
  margin: 2px 10px 5px 0;
}

.inline {
  display: inline-block;
  margin-bottom: 0;
  margin-right: 20px;
  width: 200px;
}

.error {
  // scss-lint:disable ImportantRule
  border: 2px solid $red1 !important;
}

.required {
  color: $red1;
  font-weight: bold;
}

.readOnly {
  display: block;
  word-wrap: break-word;
}
