$font-family-sans-serif:  "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;

//******** STYLE GUIDE *********//
// From the colours page of the style guide

$header-color: #0093d6;
$subheader-color: $header-color;
$icon-color: $header-color;

$call-to-action-color: #1d2757;
$footer-call-to-action-color: #96a1ab;

$box-color: #ebedef;
$outline-color: #d2d3d3;

$left-nav-offstate-color: #55b9e9;

$main-text-color: #434343;

$body-color: #ebeff2;

$text-heading-color: #043673;

$waffle-hover-colour: #d3d9dd;

//******** END STYLE GUIDE *****//

$black: #434343;
$white: #fff;

$blue1: #a4dcf4;
$blue2: #3bc2cd;
$blue3: #0094d6;
$blue35:#52b9e9;
$blue4: #083871;
$blue-link: #042061;
$blue-light: #58bae7;

$gray1: #f9f9f9;
$gray2: #d8d8d8;
$gray25: #ebedef;
$gray27: #96a1ab;
$gray3: #6c7e8c;
$gray4: #333;
$gray5: #c4cbd1;
$gray6: #555;
$gray7: #94a0ab;
$gray8: #bdbdbd;
$gray9: #f2f3f5;

$red1: #f00;

$yellow1: #f6c400;

$orange1: #f26f21;

$green1: #5bb75b;

$purple1: #824bab;

$nav-bar-height: 88px; // This also needs to be updated in notificationBar component jsx file whenever changed

$side-bar-background-color: #394046;
$side-bar-expanded-background-color: #394046;
$side-bar-item-border-color: #464d57;
$side-bar-text-color: $gray1;
$side-bar-width: 140px;
$side-bar-expanded-width: 250px;

$container-padding: 15px;

$brand-primary: $black;
$primary-color: $blue4;
$secondary-color: $blue35;

$btn-primary-bg: $primary-color;

$flag-notification-color: $blue2;
$favourite-color: $yellow1;

$shadow-color: $box-color;

$footer-height: 40px;
$footer-spacing: 20px;
$footer-color: $gray27;

$padding-base-vertical: 7px;

$wizard-active-color: $header-color;
$wizard-future-color: $gray7;
$wizard-past-color: $gray7;

$toast-color: #fff;
$toast-background-color: #000;

$file-upload-active-color: #eee;
$file-upload-reject-color: #fdd;
