@import '../../../../theme/variables.scss';

.table {
  thead {
    tr th {
      font-size: 11pt;
      vertical-align: bottom;
    }

    small {
      display: block;
    }
  }

  tbody {
    border-bottom: 2px solid $outline-color;
  }
}
