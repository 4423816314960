@import '../../../theme/variables.scss';
@import '../../../theme/mixins.scss';

$icon-size: 1.3em; // Used to make round border; ensure same height and width

.pageHeader {
  color: $primary-color;
  font-size: 29px;
  font-weight: 600;
  margin-bottom: 30px;

  div > button {
    font-size: 30px;
    margin-top: -10px;
  }
}

.backButton {
  font-size: 30px;
  margin-left: 20px;
  margin-right: 15px;

  :global(.biolabicons) {
    color: $blue-light;
  }
}

.rightSection {
  @include right-icon-section($icon-size, $blue-light);
}
