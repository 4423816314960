@import '../../../theme/variables.scss';

.seachBox {
  background-color: $white;
  margin-bottom: 30px;
  padding: 20px 15px;
}

.searchHolder {
  position: relative;
}

.searchGlass {
  font-size: 16px;
  position: absolute;
  right: 6px;
  top: 6px;

  :global(.biolabicons) {
    color: $blue-light;
  }
}
