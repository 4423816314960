@import '../../../theme/variables.scss';

:local {
  .userRow {
    cursor: pointer;

    button {
      margin-left: 15px;
    }

    button:first-child {
      margin-left: 0;
    }

    &:hover {
      background-color: $gray8;
    }
  }

  :global(.badge) {
    background-color: $gray27;
    color: $main-text-color;
    margin-bottom: 3px;
    margin-top: 3px;
  }

  .tablehead {
    span {
      cursor: pointer;
      user-select: none;
    }

    i {
      font-size: 18px;
      vertical-align: middle;
    }
  }
}
