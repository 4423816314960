@import '../../../../theme/variables.scss';

.error {
  border: 1px solid $red1;
}

.changePassword {
  label {
    width: 100%;
  }

  button {
    width: 100%;
  }
}

.changePasswordCard {
  margin-left: auto;
  margin-right: auto;
  width: 400px;
}
