@import '../../../theme/variables.scss';

.fileUpload {
  border-color: $gray27;
  border-radius: 5px;
  border-style: dashed;
  border-width: 2px;
  height: 100px;
  width: 100%;
}

.fileUploadText {
  margin: 30px auto;
  width: 190px;
}

.activeStyle {
  background-color: $file-upload-active-color;
  border-style: solid;
}

.rejectStyle {
  background-color: $file-upload-reject-color;
  border-style: solid;
}
