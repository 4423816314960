@import '../../../theme/variables.scss';

.cards {
  margin-top: 30px;
  position: relative;
  width: 100%;


  .tile {
    float: left;
    height: 250px;
    margin-left: 4%;
    margin-top: 0;
    width: 48%;

    h3 {
      text-align: center;
    }

    &:first-child {
      margin-left: 0;
      margin-top: 0;
    }

    tr {
      border-top: 1px solid $outline-color;

      &:first-child {
        border-top: 0;
      }
    }

    td {
      border-top: 0;

      &:nth-child(2) {
        font-weight: bold;
        text-align: right;
      }
    }
  }
}
