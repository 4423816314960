@import '../../../theme/variables.scss';

.card {
  background-color: $white;
  position: relative;
  width: 100%;

  // Space out consecutive cards
  + .card {
    margin-top: 20px;
  }

  + :global(.card) {
    margin-top: 20px;
  }

  h3 {
    text-align: center;
    i { margin-right: 8px; }
  }

  hr {
    margin: 10px 0;
  }

  :global(.table) {
    margin: 0;

    tr,
    td {
      border: 0;
    }

    :global(.form-group) {
      margin-bottom: 0;
    }
  }

  .header {
    background-color: $header-color;
    color: $white;
    max-height: 40px;
    padding: 10px 10px 10px 20px;
    position: relative;
    text-align: left;

    &.lightHeader {
      background-color: $blue-light;
    }

    &.noPadding {
      padding: 0;
    }
  }

  .body {
    font-size: 13px;
    padding: 20px;

    &.noPadding {
      padding: 0;
    }

    .separator {
      color: $header-color;
      font-weight: bold;
    }
  }

  .stripedColumns {
    td:nth-child(2n) {
      background-color: $white;
    }

    td:nth-child(2n-1) {
      background-color: $box-color;
    }
  }

  .footer {
    padding: 5px 12px;

    button {
      width: 100%;
    }
  }
}
