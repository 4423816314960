@import '../../../theme/variables.scss';
@import '~bourbon/app/assets/stylesheets/bourbon';

@mixin step-triangle($current-colour, $next-colour) {
  &::after {
    @include triangle(40px 15px, $white, right);
    background-color: $next-colour;
    content: ' ';
    left: -10px;
    position: absolute;
    top: 0;
    z-index: 10;
  }

  &::before {
    @include triangle(40px 15px, $current-colour, right);
    content: ' ';
    left: -12px;
    position: absolute;
    top: 0;
    z-index: 20;
  }

  &:first-child {
    &::before,
    &::after {
      content: none;
    }
  }
}

// Split steps into proper widths
@for $i from 1 through 20 {
  .steps-#{$i} {
    .step {
      width: 100% / $i;
    }
  }
}

.step {
  color: $white;
  display: inline-block;
  font-size: 17px;
  font-weight: 600;
  height: 40px;
  padding: 7px 20px;
  position: relative;
  text-align: center;
}

.number {
  float: left;
}

.active {
  @include step-triangle($wizard-past-color, $wizard-active-color);

  background-color: $wizard-active-color;

  + .future {
    @include step-triangle($wizard-active-color, $wizard-future-color);
  }
}

.future {
  @include step-triangle($wizard-future-color, $wizard-future-color);

  background-color: $wizard-future-color;
}

.past {
  @include step-triangle($wizard-past-color, $wizard-past-color);

  background-color: $wizard-past-color;
}

.wizard {
  position: relative;
}
